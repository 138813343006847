<template>
  <div class="social-board">
<!--    <a class="social-board__item instagram-item" title="Инстаграм" target="_blank" href="https://www.instagram.com/tomsk.siberia/?igshid=1uljkrkz2fhaq">-->
<!--      <Icon width="36" height="36" viewport="0 0 24 30" xlink="instagram-icon"/>-->
<!--    </a>-->
    <a class="social-board__item vk-item" title="ВКонтакте" target="_blank" href="https://vk.com/traveltomsk">
      <Icon width="36" height="36" viewport="0 0 30 34" xlink="vk-icon"/>
    </a>
<!--    <a class="social-board__item facebook-item" title="Фейсбук" target="_blank" href="https://www.facebook.com/traveltomsk/">-->
<!--      <Icon width="36" height="36" viewport="0 0 10 26" xlink="facebook-icon"/>-->
<!--    </a>-->
    <a class="social-board__item telegram-item" title="Телеграм" target="_blank" href="https://t.me/joinchat/51UROJOPIFAyZjM6">
      <Icon width="36" height="36" viewport="0 0 24 24" xlink="telegram-icon"/>
    </a>
<!--    <a class="social-board__item youtube-item" title="Ютуб" target="_blank" href="https://www.youtube.com/channel/UCkJ8jL1VAcZLWlLKg6DJeJA/videos">-->
<!--      <Icon width="36" height="36" viewport="0 0 26 30" xlink="youtube-icon"/>-->
<!--    </a>-->
    <a class="social-board__item zen-item" title="Яндекс.Дзен" target="_blank" href="https://zen.yandex.ru/id/5d9c137be4fff000aef9f303">
      <Icon width="36" height="36" viewport="0 0 26 32" xlink="zen-icon"/>
    </a>
  </div>
</template>

<script>
export default {
  name: 'SocialBoard',
};
</script>

<style lang="scss" scoped>
  .social-board {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
    position: absolute;
    top: 100px;
    right: 20px;
    max-width: 36px;
    border-radius: 4px;
    z-index: 1;
    overflow: hidden;

    &__item {
      display: block;

      &.instagram-item {
        padding-bottom: 2px;
      }

      &.facebook-item {
        padding: 0 10px;
      }

      &.vk-item {
        padding-top: 8px;
      }

      &.telegram-item {
        padding-top: 3px;
        padding-bottom: 2px;
      }

      &.youtube-item {
        padding-top: 10px;
        padding-bottom: 2px;
      }
    }
  }
</style>
